<template>
  <v-container
    fluid
    flex
    class="grey lighten-4 text-center height-100 d-flex flex-column pa-0 pt-4 pt-md-10"
  >
    <!-- CONTENT -->
    <slot />

    <!-- HOME -->
    <a @click="$router.push('/')" class="top">
      <v-icon x-large>mdi-home</v-icon>
    </a>
  </v-container>
</template>

<script>
export default {
  name: 'CenteredFullPage',

  props: {
    image: { type: Boolean, default: false }
  }
}
</script>

<style scoped>
.height-100 {
  height: 100vh;
}
.top {
  position: absolute;
  top: 50px;
  right: 50px;
}
.container {
  background-image: url('../assets/img/medical-survey.svg');
  background-position: bottom left;
  background-size: 40%;
}
</style>
